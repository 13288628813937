@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    /* --background: 0 0% 100%; */
    --background: 330, 13%, 97%;
    --foreground: 222.2 47.4% 11.2%;

    /* --muted: 210 40% 96.1%; */
    --muted: 0, 0%, 96%;
    /* --muted-foreground: 215.4 16.3% 46.9%; */
    --muted-foreground: 206, 13%, 37%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    /* --accent: 210 40% 96.1%; */
    --accent: 0, 0%, 94%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    /* --background: 224 71% 4%; */
    --background: 0, 0%, 3%;
    --foreground: 213 31% 91%;

    /* --muted: 223 47% 11%; */
    /* --muted: 0, 0%, 7%; */
    /* --muted-foreground: 215.4 16.3% 56.9%; */
    /* --muted-foreground: 0, 0%, 59%; */

    /* --accent: 216 34% 17%; */
    /* --accent: 0, 0%, 14%; */
    /* --accent-foreground: 210 40% 98%; */

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    /* --popover: 224 71% 4%; */
    --popover: 0, 0%, 7%;
    /* --popover-foreground: 215 20.2% 65.1%; */
    --popover-foreground: 0, 0%, 59%;

    /* --border: 216 34% 17%; */
    --border: 0, 0%, 16%;
    /* --input: 216 34% 17%; */
    --input: 0, 0%, 16%;

    /* --card: 224 71% 4%; */
    --card: 0, 0%, 7%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

/* --background: 240 10% 3.9%;
--foreground: 0 0% 98%;

--card: 240 10% 3.9%;
--card-foreground: 0 0% 98%;

--popover: 240 10% 3.9%;
--popover-foreground: 0 0% 98%;

--primary: 0 0% 98%;
--primary-foreground: 240 5.9% 10%;

--secondary: 240 3.7% 15.9%;
--secondary-foreground: 0 0% 98%;

--muted: 240 3.7% 15.9%;
--muted-foreground: 240 5% 64.9%;

--accent: 240 3.7% 15.9%;
--accent-foreground: 0 0% 98%;

--destructive: 0 62.8% 30.6%;
--destructive-foreground: 0 0% 98%;

--border: 240 3.7% 15.9%;
--input: 240 3.7% 15.9%;
--ring: 240 4.9% 83.9%; */

h1 {
  @apply scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl;
}
h2 {
  @apply scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0
}
h3 {
  @apply scroll-m-20 text-2xl font-semibold tracking-tight
}
h4 {
  @apply scroll-m-20 text-xl font-semibold tracking-tight
}
p {
  @apply leading-7
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    overflow: hidden;
  }
}